import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import {logout, addProfile,addToken } from '../Reducers/Actions'
import { useHistory } from 'react-router'
import axiosConfig from '../Utililty/AxiosConfig'

const CheckLoggingStatus =()=>{
    const history = useHistory()
    const key = useSelector(state=>state.token)
    const [auth, setAuth] = useState(false)

    const dispatch = useDispatch()

    //console.log('-----Checking logging-----')

    const handleRefresh = async()=>{
        
            const refreshURL = '/users/admin/refresh_token'
            const res = await axiosConfig(key).post(refreshURL, {refreshToken:key.refreshToken})
            if(res.data.data){

                dispatch(addToken(res.data.data))
            }else if(res.data.error){

                    setAuth(false)
                    dispatch(logout())
                    history.push('/')
                

            }
        
    }

    const isTokenExpired = async() =>{
        const checkingURL = '/users/profile'
        const res = await axiosConfig(key).get(checkingURL)
        if(res.data.error){
            setAuth(false)
            dispatch(logout())
            history.push('/')
        }else if(res.data && !res.data.error){
            handleRefresh()
            setAuth(true)
        }
    }


    useEffect(()=>{
        isTokenExpired()
        //refresh when access to new page or refresh browser
    },[])

    useEffect(()=>{
        const timer = setTimeout(() => {
            dispatch(logout())
            history.push('/')
        }, 1800000);
          return () => clearTimeout(timer);
    },[])

    return auth
    //return isTokenExpired()
}


export default CheckLoggingStatus