import React, {useState, useEffect} from 'react'
import axios from 'axios'
import axiosConfig from '../../components/Utililty/AxiosConfig'
import {useSelector} from 'react-redux'
import AlertCard from '../../components/Chart/AlertCard'
import { Grid, makeStyles, Typography, Container } from '@material-ui/core'
import GeoMap from '../../components/Search/GeoMap'



const useStyle = makeStyles({
        
})

const AlertDisplay = (props) =>{
    const classes = useStyle()
    const [alertInfo, setAlertInfo] = useState([])
    const [locationInfo, setLocationInfo] = useState([])
    const [counter, setCounter] = useState(0);
    const [selected, setSelected] = useState([])
    const key = useSelector(state=>state.token)

    const handleSelected =(selected)=>{
        setSelected([selected.Latitude,selected.Longitude])
        console.log(selected)
        
    }

    const getAlertInfo = async()=>{
        const url = "users/game/listall/sort" //
        const res = await axiosConfig(key).get(url)
        if(res.data){
            setAlertInfo(res.data.data)
            console.log(res.data.data)
        }else{
            setAlertInfo([])
        }
    }

    
    /*
    const getAlertLocation = async()=>{
        const url = ""
        const res = await axiosConfig(key).get(url)
    }
    */

    useEffect(()=>{
        setCounter(counter+1)
        console.log(key)
    },[])

    useEffect(()=>{
        if(key && key.token){
            getAlertInfo()
        }
    },[counter])

    useEffect(()=>{
        console.log(alertInfo)
        
    },[alertInfo])

    return (
        //home page html
            <Grid container spacing={2}  style={{marginTop:'0vh', minHeight:'100px', maxHeight:'25%'}}>
                <Grid item xs={12}>
                    <Typography varient={'h4'}>
                        <b>Latest Visitors</b>
                    </Typography>
                            

                </Grid>
                <Grid item xs={12} md={12} style={{marginTop:'0vh', minHeight:'300px'}}>
                    <AlertCard data={alertInfo} handleSelected={handleSelected}/>
                </Grid>
                {/* <Grid item xs={12} md={6} style={{minHeight:'300px'}}>
                    <AlertCard data={alertInfo} handleSelected={handleSelected}/>
                </Grid> */}
            </Grid>

    )

}

export default AlertDisplay