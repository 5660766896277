import React from 'react';
import CardElements from './CardElements';
import './Card.css';




function Card() {
    return (
        <>  
          <div className='card'>
             
           
              <div className="grid-container">
                  
               
                 <div class="cell-2">
                     <h3>Tsuen Wan District</h3>
                    
                     
                      <div class="container-2" >
                       <div class="card1">
                         <h2>Temperature</h2>
                         <p><div class="card__text">25.5°C</div></p>
                       </div>
                       <div class="card1">
                         <h2>Humidity</h2>
                         <p><div class="card__text">85%</div></p>
                       </div>
                       <div class="card1">
                         <h2>pH Value</h2>
                         <p><div class="card__text">6</div></p>
                       </div>
                       <div class="card1">
                         <h2>Light Intensity</h2>
                         <p><div class="card__text">95%</div></p>
                       </div>
                       <div class="card1">
                         <h2>PM2.5</h2>
                         <p><div class="card__text">32</div></p>
                       </div>
                       <div class="card1">
                         <h2>NO2</h2>
                         <p><div class="card__text">34</div></p>
                       </div>
                       <div class="card1">
                         <h2>Temperature</h2>
                         <p><div class="card__text">25.5°C</div></p>
                       </div>
                       <div class="card1">
                         <h2>Temperature</h2>
                         <p><div class="card__text">25.5°C</div></p>
                       </div>
                       <div class="card1">
                         <h2>Temperature</h2>
                         <p><div class="card__text">25.5°C</div></p>
                       </div>
                       <div class="card1">
                         <h2>Temperature</h2>
                         <p><div class="card__text">25.5°C</div></p>
                       </div>
                       <div class="card1">
                         <h2>Temperature</h2>
                         <p><div class="card__text">25.5°C</div></p>
                       </div>
                       <div class="card1">
                         <h2>Temperature</h2>
                         <p><div class="card__text">25.5°C</div></p>
                       </div>
 
                    </div> 
                      
                      
 
                 
                      
                 </div>
 
                 <div class="cell-3">
                    <div className="left__container">
                        <div class="left__card1">
                             ff
                        </div>
                        <div className="left__sub__container">
                        
                            <div class="left__card2">
                                <div className="left__small__card">
                                 11:15
                                </div>
                            </div>
                            <div class="left__card2">
                                 PM2.5 is over 30.
                            </div>

                            <div class="left__card2">
                               <div className="left__small__card">
                                11:59
                                </div>
                            </div>
                            <div class="left__card2">
                                 NO2 is over 25.
                          </div>
                        </div>
                      
                      
                       <div class="left__card2">
                             fe
                       </div>
                       <div class="left__card3">
                            dc
                       </div>
                    </div>
                    

                    
                    
                 </div>
 
                 <div class="cell-4">
                    
                 </div>
             
                 
                 {/* <div className="cards_wrapper">
                      <ul className="cards_items">
                          <CardElements
                          text='test 123'  />
                      </ul>
                 </div> */}
             </div>
         </div>
      

        

        </>
            
        

    
    )
}


 
export default Card
