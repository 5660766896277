import LoggedReducer from './isLogged'
import TokenReducer from './token'
import testReducer from './testing'
import UserProfileReducer from './userProfile'
import { combineReducers } from 'redux'

const AllReducers = combineReducers({
    isLogged : LoggedReducer,
    token: TokenReducer,
    userProfile:UserProfileReducer,
    testing:testReducer,
})

export default AllReducers