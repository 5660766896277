
const test = (state = 0 , action)=>{
    switch(action.type){
        case 'ADD':
            if(state>0){
            }
            return state+1;
        case 'REDUCE' :
            return state-1;
        default:
            return state;
    }

}

export default test