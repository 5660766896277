import React,{useState, useEffect} from 'react'
import { Avatar, Button, Container,Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';
import UpdateInfoForm from './UpdateUserInfoForm';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    '@media only screen and (max-width: 480px)': {
        width:'37.5vw',
        height: '37.5vw',
    },
      width:'180px',
      height: '180px',
      marginTop: '5vh',
      justifyContent: 'center',
      alignItems: 'center'
      
    },
  }));




const UserInfo =(props)=>{
    const classes = useStyles()
    const userProfile = useSelector(state => state.isLogged)

    
    const [updateInfo, setUpdateInfo] = useState({username:""})

    useEffect(()=>{
        if(userProfile && userProfile.username!==""){
            const newInfo = {...updateInfo}
            newInfo.username = userProfile.username
            setUpdateInfo(newInfo)
        }
    },[userProfile])
    
    //console.log(props)
    return (
            <Container style={{marginTop: '1vh'}}>
            <Grid
                
                container
                spacing={3}
                direction="column"         
                alignContent='center' 
                alignItems='center'

            >
                <Grid item>
                    <Avatar alt={updateInfo.username}  srcSet="/" className={classes.orange}></Avatar>
                </Grid>   

                <Grid item> 
                    <UpdateInfoForm type={'userInfo'} data={updateInfo}/>
                </Grid>
                
                <Grid item> 
                    <Button
                            form="userInfo"
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"   
                        >
                             &nbsp;&nbsp;Submit&nbsp;&nbsp;
                    </Button>                
                </Grid>

            </Grid>

        </Container>


























        
    //     <Container >
    //         <Grid
                
    //             container
    //             spacing={0}
    //             direction="column"
    //             xs={3} alignContent='center' alignItems='center'
                
               
                
    //         >
    //             <Grid item xs={6}>
    //                 <Avatar alt={props.user.username}  srcSet="/" className={classes.orange}></Avatar>
    //             </Grid>   

    //         </Grid>
    //         <br />
    //         <br />
    //         <Grid container xs={3} alignContent='center' alignItems='center'>
    //             <Grid item> 
    //                 <UpdateInfoForm type={'userInfo'} data={props.user}/>
    //             </Grid>
    //         </Grid>
    //         <br />
    //         <br />
    //         <Grid container>
    //             <Grid item > 
    //                 <Button
    //                         form="userInfo"
    //                         type="submit"
    //                         fullWidth
    //                         variant="contained"
    //                         color="primary"
    //                     >
    //                         Submit
    //                 </Button>                
    //             </Grid>
    //         </Grid>

    //     </Container>


     )

}

export default UserInfo

