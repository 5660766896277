import React, {useState, useEffect} from 'react'
import AlertDisplay from './AlertDisplay'
import RealTimeDataDisplay from './RealTimeDataDisplay'
import LocationInfoMenu from '../../components/Utililty/LocationInfoMenu'
import { Container, Typography, Grid, Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({

})

const Latest = ()=>{
    //const key = useSelector(state=>state.token)
   // console.log(key)

    const [selected, setSelected] = useState({LocationID:0, AreaID:0})

    const handleSelectedChange =(e)=>{
        console.log(e)
        const newSelected = {...selected}
        newSelected[e.target.name] = e.target.value
        setSelected(newSelected)
    }

    //latest home page
    return (
        <Container maxWidth={false}> 
            <Grid container style={{marginTop:'1vh'}}>
                <Grid item xs={12}>
                    <AlertDisplay />
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop:'1vh'}}>
                <Grid item xs={12}>
                    {/* <LocationInfoMenu value={selected} handleChanged={handleSelectedChange}/> */}
                </Grid>
                <Grid item xs={12}>
                    {/* <RealTimeDataDisplay type={'Realtime'} selected={selected}/> */}
                </Grid>
            </Grid>
        </Container>


    )

}

export default Latest
