import React, {useState, useEffect} from 'react'
import Control from '../../components/Control/Control'
import LoginVerifiy from '../../components/Login/LoginVerifiy'

const ControlPage =()=>{
    LoginVerifiy()

    return (<Control />)

}
export default ControlPage

