import React,{useEffect, useState} from 'react'
import axios from 'axios'
import {FormControl, InputLabel,Button, FormLabel, Container, Grid, Select, MenuItem, Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import ExcelPreview from './ExcelPreview'
import DateFnsUtils from '@date-io/date-fns';
//import DateRangePicker from '../Utililty/DateRangePicker'
import LocationInfoMenu from '../Utililty/LocationInfoMenu'
import ReportSelectMenu from './ReportSelectMenu'

import { setDate } from 'date-fns'


const useStyles = makeStyles({
    root: {
      
    },})

const Report=(props)=>{
    const [reportOption, setReportOption] = useState({route:"df", LocationID:0, AreaID:0, startdate:null, enddate:null})
    //const [locationInfo, setLocationInfo] = useState([])
    //const [reportData, setReportData] = useState([])
    

    const handleOptionChanged =(e)=>{
        //console.log(e)
        const newReportOption = {...reportOption}
        console.log(e.target)
        newReportOption[e.target.name] = e.target.value;
        setReportOption(newReportOption)
    }

    const handleDateRangeChange =(action, date)=>{
        console.log(date)
        const newDateRange = {...reportOption}
        newDateRange[action] = date
        setReportOption(newDateRange)
    }


    return (
        // report html
        <Container maxWidth={false}> 
            <Grid container spacing={1} style={{width:'100%', marginTop:'2vh'}}>
                <Grid item xs={12}>
                    <Grid container spacing={3} >
                        <Grid item>
                    <Typography varient={'h4'}>
                        <b>Visitors' Data</b>
                    </Typography>
                            {/* <ReportSelectMenu handler={handleOptionChanged} reportOption={reportOption}/> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item >
                            {/* <LocationInfoMenu title={""} value={reportOption} handleChanged={handleOptionChanged}/> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>

                    <Grid container spacing={1} justify="space-around">
                        <Grid item xs={12}>
                            {/*<DateRangePicker dateRange={reportOption} handler={handleDateRangeChange}/>*/}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <ExcelPreview options={reportOption}/>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>       
        </Container>
    )


}

export default Report