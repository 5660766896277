const initialState = {isLogged:false}

const loggedReducer =(state=initialState, action)=>{
    switch(action.type){
        case 'SIGN_IN':
            if(state!==true){
                const isLogged = true
                const localUser = action.payload
                return {... localUser, isLogged}
            }
            return state
        case 'SIGN_OUT':
            if(state!==false)
                state.isLogged = false
                state.localUser = {}
                return {... state}

      
        case 'login_status':
            const loginStatus = state.loginStatus
            return loginStatus
        default:
            return state
    }

}

export default loggedReducer