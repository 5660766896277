import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { addToken, logout } from '../Reducers/Actions';
import { useHistory } from 'react-router-dom';




const AxiosConfig = (key, history) =>{
    //console.log(key)
    
    const axiosInstance = axios.create({
        baseURL: 'https://api.techunt.hkrnd.com/api/v1',
        
    });
    axiosInstance.defaults.headers['Authorization'] =  `Bearer ${key.token}`;
    //axiosInstance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    axiosInstance.interceptors.request.use((request)=> {
        return request
    },
    (error)=>{
        console.log(error)
        return error
    })

    axiosInstance.interceptors.response.use((response)=> {
        console.log(response)
        return response
    },
    (error)=>{
        
        if(error.message==='Network Error' && !error.response){

            return {data:{error:"NETWORK ERROR"}}
        }else if(error.response){
            const {status, data, config} = error.response

            if(status === 404){
                return error.response
            }

            if(status == 400){
                return error.response
            }

            if(status === 500){
                error.response.data = {error:'JWT Expired'}
                return error.response
            }

            return error.response
        }

        

        
    })

    return axiosInstance
}

export default AxiosConfig