import React, {useState} from 'react';
import {
    ItemIcon,
    ItemLink,
    ItemTitle,
    SidebarContainer,
    SidebarLogo,
    SidebarMenu,
    SidebarMenuItem
} from './SidebarElements';
import { SidebarItems } from './SidebarItems';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InnoSpaceIcon from '../Icon/HKPCw.png'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#1b006e',

  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));




export function SubMenu(props) {
    return (
        <SidebarMenu>
            {
                props.active ? 
                    <SidebarMenuItem subitem= 'true'>
                    {
                        props.items.map((subVal, index) => {
                            return (
                                <ItemLink key={'sub'+index} to={ subVal.link } subitem='true' >
                                    <ItemIcon></ItemIcon>
                                    <ItemTitle>{ subVal.title }</ItemTitle>
                                    <ItemIcon></ItemIcon>
                                </ItemLink>
                            );
                        })
                    }
                    </SidebarMenuItem>
                : 
                    <></>
            }      
        </SidebarMenu>
    );
}



function Sidebar(props) {
    const [submenuActive, setSubmenuActive] = useState(Array(SidebarItems.length).fill(false));
    const classes = useStyles()
    const theme = useTheme();

    const container = window !== undefined ? ()=>document.body:undefined


    const handleClick = (submenuID) => {
        const temp = submenuActive.slice();
        temp[submenuID] = !temp[submenuID];
        setSubmenuActive(temp);
    }

    console.log(props)

    const tempStyle = {
        border: '3px dashed white',
        borderRadius: 5,
        padding: 15
    }

    //const container = window !== undefined ? () => window().document.body : undefined;
    //const container = () => window().document.body;
    const drawer = (

            <SidebarMenu>
                    <SidebarLogo>
                    <img src={InnoSpaceIcon} alt={"Icon"} width={"65%"} style={{margin:"2vh"}}/>
                        {/**
                         * <div>
                         *  <img src={InnoSpaceIcon} alt={"Icon"} />
                         * </div>
                         * 
                        */}
                        
                    </SidebarLogo>
                    <SidebarMenuItem>
                    { 
                        SidebarItems.map((val, key) => {
                            return (
                                <React.Fragment key={key}>
                                    <ItemLink key={'side' + key} to={ val.link } onClick={ handleClick.bind(this, key) }>
                                        <ItemIcon>{ val.icon }</ItemIcon>
                                        <ItemTitle>{ val.title }</ItemTitle>
                                        <ItemIcon>
                                        {
                                            val.nasted.length > 0 ?
                                                submenuActive[key] ? <ExpandLessIcon /> : <ExpandMoreIcon />
                                            :
                                                <></>
                                        }
                                        </ItemIcon>
                                    </ItemLink>
                                    <SubMenu items={ val.nasted } active={ submenuActive[key] } />
                                </React.Fragment>
                            );
                        })
                    }      
                    </SidebarMenuItem>
                </SidebarMenu>
      );
    
    
    return (
        
        <nav className={classes.drawer}>
            <Hidden mdUp implementation="css">
                
                <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={props.status}
                    onClose={props.open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
            </Hidden>
        </nav>        
    )
}

export default Sidebar
