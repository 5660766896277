import React,{useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { IconButton, Dialog, DialogTitle,DialogContent, DialogContentText, DialogActions, TextField, Button } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RedeemIcon from '@material-ui/icons/Redeem';

import ConfigurationForm from './ConfigurationForm'
import DeleteAlert from './DeleteAlert'

import CertIcon from '@material-ui/icons/Note';

import { makeStyles } from '@material-ui/styles';


const ConfigurationDialog =(props)=>{
   
    const [open, setOpen] = useState(false)
    //const formRef = useRef(null)
    
    //console.log(props.type)
    //console.log(props.type)
    //console.log(props.action)

    //const [formData, setFormData] = useState([])

    //console.log(props.data)
    //console.log(formRef.current)

    const handleOpen =()=>{
        setOpen(true)
    }

    const handleClose =()=>{
        setOpen(false)
    }

    const selectButtonIcon = (action) =>{
      switch(action.toUpperCase()){
        case "CREATE" :
          return <AddCircleIcon />
        case "UPDATE" :
          return <EditIcon />
        case "DELETE" :
          return <DeleteIcon />
        case "REDEEM" :
          return <RedeemIcon />
        case "CERT"   :
          return <CertIcon />

      }
    }

    //console.log(props.type)
    const useStyles = makeStyles({
      root: {
        margin: 'auto'
      },
    });

    const classes = useStyles()

    return (
      <div className={classes.root}>
      <Button type="Button" onClick={handleOpen} > 
        {selectButtonIcon(props.action)}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth='md'	>
        <DialogTitle id="form-dialog-title">Config</DialogTitle>
        <DialogContent>
           <ConfigurationForm data={props.data} type={props.type} action={props.action}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary" form={props.type+"Config"}>
            Submit
          </Button>
          
        </DialogActions>
      </Dialog>
      </div>
       
    )

}

export default ConfigurationDialog