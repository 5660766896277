import styled from 'styled-components';
//import { NavLink } from 'react-router-dom';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

export const NavbarContainer = styled.div`
    height: 70px;
    width: 100%;
    display: flex;
    justify-content:space-between;
    top: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    align-items: center;
    background-color: white;

    
`;

export const NavbarWrapper = styled.div`
    display: flex;
    padding: 15px;
    align-items: center;
    minWidth:300px
`;

export const MenuBar = styled.div`
    display: none;
    cursor: pointer;
    flexGrow:1;

    @media screen and (max-width: 425px) {
        display: flex;
    }
`;

export const TimeIndicator = styled.div`
    flexGrow:1;
    display: block;
    padding: 0 0px;
    color:black;
`;

export const Column = styled.div`

`;

export const SearchBar = styled.div`
    display: flex;
    border: 1px #e9e9e9 solid;
    border-radius: 5px;
    background: #f7f7f7;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavbarButtonBar = styled.div`
    display: flex;
    padding: 0 10px;
`;

const useStyles = makeStyles(
    (theme) => ({
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        }
    })
);

export default function CustomizedInputBase() {
    const classes = useStyles();

    return (
        <SearchBar>
            <InputBase
                className={classes.input}
                placeholder="Search..."
                inputProps={{ 'aria-label': 'Search...' }}
            />
            <IconButton type="submit" className={classes.iconButton} aria-label="search">
                <SearchIcon />
            </IconButton>
        </SearchBar>
    );
}
