import React, {useState, useEffect} from 'react'
import {
    Column,
    TimeIndicator
} from './NavbarElements';


const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'Novbember', 'December'];

const formatter = num => {
    if (num < 10) {
        return `0${num}`;
    }
    return `${num}`;
}

const getNowTime = (width) => {
    let d = new Date();
    if(width>600){
        let date = `${formatter(d.getDate())} ${MONTHS[d.getMonth()]} ${d.getFullYear()}`;
        let time = `${formatter(d.getHours())}:${formatter(d.getMinutes())}:${formatter(d.getSeconds())}`;
        return [date, time];
    }else if(width > 320){
        let date = `${formatter(d.getDate())} ${MONTHS[d.getMonth()].substring(0,3)} ${d.getFullYear()}`;
        let time = `${formatter(d.getHours())}:${formatter(d.getMinutes())}:${formatter(d.getSeconds())}`;
        return [date, time];
    }else{
        let date = `${formatter(d.getDate())}-${formatter(d.getMonth())}-${d.getFullYear().toString().substring(2,4)}`;
        let time = `${formatter(d.getHours())}:${formatter(d.getMinutes())}:${formatter(d.getSeconds())}`;
        return [date, time];
    }
 

   
}

const defaultWidth = window.innerWidth

const Timer =()=>{
    const [nowTime, setNowTime] = useState(getNowTime(defaultWidth));
    const [width, setWidth] = useState(window.innerWidth)

    function tick() {
        let newTime = getNowTime(width);
        setNowTime(newTime);
    }

   
    useEffect(() => {
    
        var timerID = setInterval(() => tick(), 1000);
        return function cleanup() {
            clearInterval(timerID);
        }
    });

    useEffect(()=>{
        
    },[width])

    useEffect(()=>{
        setWidth(window.innerWidth)
    },[window.innerWidth])

    return ( 
        <TimeIndicator>
            <Column>Date: {nowTime[0]}</Column> 
            <Column>Time: {nowTime[1]}</Column> 

        </TimeIndicator>
    )
}

export default Timer