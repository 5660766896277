import React, {useState, useEffect} from 'react'
import {InputLabel,FormLabel, Grid, MenuItem, TextField, Select, Container, FormControl, Button } from '@material-ui/core';
import axios from 'axios';
import { useSelector } from 'react-redux';
import axiosConfig from '../Utililty/AxiosConfig'
import CertGen from './CertGen'


const ControlForm =(props)=>{

    const [gatewayConfig, setGatewayConfig] = useState()
    const key = useSelector(state=>state.token)


    const handleChanged =(e)=>{
        const newGatewayConfig = {...gatewayConfig}
        newGatewayConfig[e.target.id] = e.target.value;
        setGatewayConfig(newGatewayConfig)
        //console.log(newGatewayConfig)
    }

    const handleSelectChanged = (e) =>{
        const newSelectConfig = {...gatewayConfig}
        newSelectConfig[e.target.name] = e.target.value
        setGatewayConfig(newSelectConfig)
        //setSelectedLocation(e.target.value)
    }
    
    const handleSubmit = async (e)=>{
        e.preventDefault()
        /*
        const submitUrl = "/api/v1/gateways/"+props.action
        */
        //console.log(submitUrl) 

        // const submitUrl = "/users/game/redeem"
        // await axiosConfig(key).post(submitUrl, gatewayConfig)
        //window.location.reload();
        window.open("http://localhost:3000/logo.png", "_blank")
        
        //await axiosConfig(key).post("/gateways/update/ip", gatewayConfig)
    }
    
    useEffect(() => {
        // console.log(gatewayConfig)
       //console.log(key)
    }, [gatewayConfig])

    return (


        <form onSubmit={(e)=>{handleSubmit(e);}} id="controlConfig">
            <Grid container>
                <FormLabel><b>Certificate Config</b></FormLabel>
               
            </Grid>
            <Grid container> <br /></Grid>

            <Grid container spacing={2} backgroudcolor="Red">
                    
                    <Grid item xs={12} sm={5}>
                        <InputLabel id="Action">Action</InputLabel>

                        <Select 
                            label="Action"
                            name={"Action"} 
                            value={gatewayConfig} 
                            onChange={handleSelectChanged}
                            fullWidth
                        >
                            {/* <MenuItem value={666}>Yes/No</MenuItem> */}
                            <MenuItem value={1}>Download</MenuItem>
                            <MenuItem value={0}>View</MenuItem>

                        </Select>
                    </Grid>

          
                </Grid>
            </form>
                

                
        
    )

    
}
export default ControlForm
