import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {PersistGate } from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import reduxStore from './components/Reducers/store'

const {store, persistor} = reduxStore();

ReactDOM.render(
  /** 
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  */

  <Provider store = {store} >
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
