import axiosConfig from "../../Utililty/AxiosConfig";
import { useSelector } from "react-redux";

export const Loggingin =(userInfo)=>{
    //console.log(userInfo)
    //await axiosConfig('').post('/users/login', {email:"Demo@example.com", password:"Testing@!23"})
    
    return {
        type:'SIGN_IN',
        payload:userInfo
    };
};

export const logout=()=>{
    return{
        type:'SIGN_OUT',
    }
}

export const isLogged =()=>{
    return{
        type:'login_status',
    }
}


export const addToken =(token)=>{
    return {
        type:'ADD_TOKEN',
        token
    };
};

export const updateToken=(token)=>{

    return {
        type:'UPDATE_TOKEN',
        token
    }
}

export const getToken=()=>{
    return{
        type:"GET_TOKEN"
    }
}

export const addRefreshToken =(refreshToken)=>{
    return {
        type:'ADD_REFRESHTOKEN',
        refreshToken,
    }
}

export const addProfile=(profile)=>{
    return{
        type:'UPDATE_PROFILE',
        profile,
    }
}

export const testing =()=>{
    return{
        type:'ADD'
    }
}
