import React,{useState, useEffect} from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import SearchResultTable from '../Search/SearchResultTable'
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const ControlResult =()=>{

    const [gatewaysData, setGatewaysData] = useState([])
    const key = useSelector(state=>state.token)

    const getGatewayData = async(d) =>{

        const url = 'https://api.techunt.hkrnd.com/api/v1/users/game/listall/sort'
        //console.log(axios.defaults)
        //console.log(key)
        axios.defaults.headers.common['Authorization'] = `Bearer ${key.token}`;
        

        //console.log(axios.defaults)

        const response = await axios.get(url);
        //console.log(response.data[20].rating[0]._id.imdbID);
        //console.log(response.data);
        setGatewaysData(response.data.data);
        //setGetCount(true)
      
     
    }

    const cols = [
        {field: 'user_id', headerName:"ID", type: 'number', width:95, hide: true},
        {field:"redeemCode", headerName:"Redeem Code", headerAlign: 'center', align: 'center', width:200}, //175
        {field:"username", headerName:"Username", headerAlign: 'center', align: 'center', width:170},
        {field:"institution", headerName:"Institution", headerAlign: 'center', align: 'center', width:180},
        {field:"number_of_participants", headerName:"N.O.P.", headerAlign: 'center', align: 'center', width:120, hide: true}, //100
        {field:"isClear", headerName:"Clear", headerAlign: 'center', type: 'boolean', width:150}, //160
        {field:"isRedeemed", headerName:"Redeemed", headerAlign: 'center', type: 'boolean', width:150}, //140
        {field:"created_at", headerName:"Date", headerAlign: 'center', align: 'center',  width:150}, //145

    ]
    /*
    const rows = [
        {id:13,LocationName:"Tin Shui Wai", AreaName:"Area A" },
        {id:1002,LocationName:"Tin Shui Wai", AreaName:"Area A" },
        {id:1003,LocationName:"Tin Shui Wai", AreaName:"Area A" },
        {id:1004,LocationName:"Tin Shui Wai", AreaName:"Area A" }]
    */
    useEffect(() => {
        getGatewayData()
    },[])

    return (
        <Grid container spacing={4}  style={{marginTop:'0vh', minHeight:'100px', maxHeight:'25%'}}>
            <Grid item xs={12}>
                <Typography varient={'h4'}>
                    <b>Redeem Page</b>
                </Typography>
                        

            </Grid>
            <Grid container xs={12} spacing={3} style={{margin:'0px', height:'80vh', width:'100%'}}>
                <Grid item xs={12}>
                    <SearchResultTable rows={gatewaysData} columns={cols} type="control"/>
                </Grid>
            </Grid>

        </Grid>

        

    
    )

}

export default ControlResult