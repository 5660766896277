import React from 'react'
import Certificate from '../../components/Report/Certificate'
import LoginVerifiy from '../../components/Login/LoginVerifiy'

const CertificatePage =()=>{

    LoginVerifiy()
    
    return(

        <Certificate />
    )
}

export default CertificatePage