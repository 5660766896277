import React from 'react'
import { makeStyles } from '@material-ui/core'

const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'fixed',
      maxWidth:false
      
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: {
        height: '70px',
        width: '100%',
        display: 'flex',

        top: '0',
        alignItems: 'center',
        color: 'white',
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#00506C',
  
    },
    content: {
      flexGrow: 1,
      paddingTop: '1vh',
      paddingLeft: '1vw',
      paddingRight: '1vw',
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      }
    },
  }));

  export default useStyles;