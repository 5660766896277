const initialState = {token:"", refreshToken:""}

const token = (state = initialState  , action)=>{
    switch(action.type){
        case 'ADD_TOKEN':
			const token = action.token.token
			const refreshToken = action.token.refreshToken
            return {...state,token, refreshToken};
        case 'SIGN_OUT':
            state = initialState
            return {...state}
        default :
            return state;
        
    }

}

export default token