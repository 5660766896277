import React, {useState, useEffect} from 'react'
import { DataGrid } from '@material-ui/data-grid'
import {Grid} from '@material-ui/core';

const DisplayTable = (props) =>{

    return(
        
            <Grid item style={{height:'100%'}}>
                {(props.handleSelected!="")?
                    <DataGrid  disableColumnMenu autoPageSize rows={props.rows} columns={props.cols} pageSize={5} onRowSelected={(e)=>{props.handleSelected(e.data)}}/>
                    :
                    <DataGrid  disableColumnMenu autoPageSize rows={props.rows} columns={props.cols} pageSize={5}/>
                }      
            </Grid> 
    )

}

export default DisplayTable