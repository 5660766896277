import React, {useState, useEffect} from 'react'
import { Button, Grid, Container } from '@material-ui/core';
import axios from 'axios'
import SearchResultTable from './SearchResultTable';
import { useSelector } from 'react-redux';
import AxiosConfig from '../Utililty/AxiosConfig';
import Typography from '@material-ui/core/Typography';
//import axios from 'axios';


const GatewayResult =()=>{
        //const [data, setData] = useState([])
        //const [columns, setColumns] = useState([]);

        const [location, setLocation] = useState({})
        const [gatewaysData, setGatewaysData] = useState([])
        const [errMessage, setErrMessage] = useState("")
        const key = useSelector(state=>state.token)

        const getGatewayData = async() =>{
            try{
              const url = '/users/game/setting'
              const response = await AxiosConfig(key).get(url);
              
              if(response.data){
                setGatewaysData(response.data.data);
              }

            }catch(err){

            }
            //console.log(axios.defaults)
            //console.log(key)
            //axios.defaults.headers.common['Authorization'] = `Bearer ${key.token}`;
            

            //console.log(axios.defaults)

            
            //console.log(response.data[20].rating[0]._id.imdbID);
            //console.log(response.data);
            //setGetCount(true)
          
         
        }
        

        /**
         * const cols = (data) =>{
         *   const result = []
         *   for(const [key, value] of Obejct.entries(data)){
         *      
         *   }
         *    
         * }
         */
        const cols = [
        { field: 'Game', headerName: 'Tag', type: 'string',width:105, headerAlign: 'center', align: 'center'/*,  hide: true */},
        { field: 'difficultyTag', headerName: 'Difficulty', type: 'string', headerAlign: 'center', align: 'center', width:135},
        { field: 'game1', headerName: 'Game1', type: "boolean", headerAlign: 'center', align: 'center', width:122},
        { field: 'game2', headerName: 'Game2', type: 'boolean', headerAlign: 'center', align: 'center', width:122},
        { field: 'game3', headerName: 'Game3', type: "boolean", headerAlign: 'center', align: 'center', width:122},
        { field: 'game4', headerName: 'Game4', type: 'boolean', headerAlign: 'center', align: 'center', width:122},
        { field: 'game5', headerName: 'Game5', type: 'boolean', headerAlign: 'center', align: 'center', width:122},
        { field: 'game6', headerName: 'Game6', type: "boolean", headerAlign: 'center', align: 'center', width:122},
        { field: 'game7', headerName: 'Game7', type: 'boolean', headerAlign: 'center', align: 'center', width:122}


        ]
        
        useEffect(() => {
          getGatewayData()
        }, [location])
        //const data = [];
        //const data = setData(GetData());

        /**
         * const GetData = async(query)=>{
         *      const url = ""
         *      const response = await axios.get(url);
         *
         * }
         * 
         */

    
        return (

          <Container maxWidth={false}>
            <Grid item xs={12} style={{margin:'15px'}}>
              <Typography varient={'h4'}>
                  <b>Game Setting</b>
              </Typography>
                      

          </Grid>
          <Grid container xs={12} spacing={3} style={{margin:'0px', height:'60vh', width:'100%'}}>
            <Grid item xs={12}>
              <SearchResultTable rows={gatewaysData} columns={cols} type="gateways" table="Game"/>
            </Grid>
          </Grid>
          </Container>
        );

        


}





export default GatewayResult;