import { React, useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom'
//import CheckLogin from './GetUserProfile';
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux';
import { logout, login } from '../Reducers/Actions';
import LoginVerifiy from '../Login/LoginVerifiy';
import Profile from '../Login/Profile'
import Timer from './Timer'
import UserMenu from './UserInfo'
import CustomizedInputBase, {
    Column,
    MenuBar,
    NavbarButtonBar,
    NavbarContainer,
    NavbarWrapper,
    TimeIndicator
} from './NavbarElements';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem'
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SendIcon from '@material-ui/icons/Send';
import {
    BrowserRouter as Router,
    Route as DefaultRoute,
    Switch,
    Redirect
  } from 'react-router-dom'
import { Button, Toolbar } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core';
import { AppBar } from '@material-ui/core';

const drawerWidth = 240;


const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'Novbember', 'December'];

const getNowTime = () => {
    let d = new Date();

    let date = `${formatter(d.getDate())} ${MONTHS[d.getMonth()]} ${d.getFullYear()}`;
    let time = `${formatter(d.getHours())}:${formatter(d.getMinutes())}:${formatter(d.getSeconds())}`;
    return [date, time];
}

const formatter = num => {
    if (num < 10) {
        return `0${num}`;
    }
    return `${num}`;
}

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      flexGrow: 1,
      height: '70px',
      width: '100%',
      backgroundColor: 'white',
      
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: 'white',

      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#00506C',
  
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }));



function Navbar(props) {
    //const [nowTime, setNowTime] = useState(getNowTime());
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory()
    const classes = useStyles()
    //const loggingStatus = useSelector(state=>state.isLogged);
    //console.log("LOGGING: " + loggingStatus)
    console.log(props)

    return (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
              <div className={classes.menuButton} >
                <IconButton edge="start" onClick={props.open}>
                  <MenuIcon />
                </IconButton>
              </div>
              <Timer />
              <div style={{flexGrow:'1'}} />
              <IconButton>
                <NotificationsIcon />
              </IconButton>
              <UserMenu />
            </Toolbar>
        </AppBar>
    );
}

export default Navbar
