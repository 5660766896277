import React,{useRef, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
//import CheckLogin from './GetUserProfile';
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux';
import { logout, login } from '../Reducers/Actions';
import {Avatar,Container,IconButton, ClickAwayListener,Grow,Paper,Popper,Menu,MenuItem,MenuList} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/styles';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    paper: {
        backgroundColor:"Blue",
        position:"relative"
    },
    container:{
        marginRight:2
        
    },
  }));

const UserMenu =(props)=>{
    
    const classes = useStyles();
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.isLogged)
    const history = useHistory();
    //const classes = useStyles();
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        history.push('/User')
    };

    const handleLogout = () =>{
        dispatch(logout())
        history.push("/")
    }

    return(
        <div>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
              >
                <AccountCircleIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
              >
                
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
        </div>
    )



    


}

export default UserMenu

