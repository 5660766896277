import React from 'react'
import Report from '../../components/Report/Report'
import LoginVerifiy from '../../components/Login/LoginVerifiy'

const ReportPage =()=>{

    LoginVerifiy()
    
    return(

        <Report />
    )
}

export default ReportPage