import React, {useState, useEffect} from 'react'
import ControlResult from './ControlResult'
import { Container } from '@material-ui/core'


const Control =()=>{

    return(
        <Container maxWidth={false}>
            <ControlResult />
        </Container>
    )

}

export default Control