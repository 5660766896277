import {InputLabel,FormControl,Select, Avatar,Button,CssBaseline,FormControlLabel,Checkbox,Link,Grid,Box,Typography,Container, TextField, FormLabel, MenuItem, FormGroup } from '@material-ui/core';

import axios from 'axios'
import React, { useState, useEffect } from 'react';
import axiosConfig from '../Utililty/AxiosConfig';
import { useSelector, useDispatch } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';


const GatewayConfigForm =(props)=>{
    const key = useSelector(state=>state.token)
    //console.log(props.data)

    const [gatewayConfig, setGatewayConfig] = useState({difficulty: 1, game1: 0, game2: 0, game3: 0, game4: 0, game5: 0, game6: 0, game7: 0})
    //const [updateCount, setUpdateCount] = useState(0);
    //const [selectedLocation, setSelectedLocation] = useState({})
    // const [info, setInfo] = useState([])
    // const [submitted, setSubmitted] = useState(false);
    //console.log(props.data)
    console.log(props)

    const handleChanged =(e)=>{
        const newGatewayConfig = {...gatewayConfig}
        newGatewayConfig[e.target.id] = e.target.value;
        setGatewayConfig(newGatewayConfig)
        //console.log(newGatewayConfig)
    }

    const handleSelectChanged = (e) =>{
        const newSelectConfig = {...gatewayConfig}
        newSelectConfig[e.target.name] = e.target.value
        setGatewayConfig(newSelectConfig)
        //setSelectedLocation(e.target.value)
    }

    const handleChecked = (e) =>{
        const newSelectConfig = {...gatewayConfig}
        newSelectConfig[e.target.name] = e.target.value
        setGatewayConfig(newSelectConfig)
    }
    
    const handleSubmit = async (e)=>{
        e.preventDefault()
        /*
        const submitUrl = "/api/v1/gateways/"+props.action
        */
        //console.log(submitUrl)
        const submitUrl = "/users/game/set"
        console.log(gatewayConfig)
        await axiosConfig(key).post(submitUrl,gatewayConfig)
        .then((res)=>{console.log(res)}).catch(err=>console.log(err))

        window.location.reload();
    }

    return(
    <form onSubmit={(e)=>{handleSubmit(e)}} id="gatewaysConfig">
        <Grid container spacing={3} alignItems="center">
            
            <Grid item>
                <FormLabel><b>Difficulty</b></FormLabel>
            </Grid>
            <Grid item>
                    <FormControl>
                    <RadioGroup row aria-label="difficulty" name="diffculty" defaultValue="1">
                        <FormControlLabel
                            // disabled={(props.action!="create")?true:false}
                            // defaultValue={props.data.isOutdoor}
                            value="0"
                            name="difficulty"
                            onChange={handleChecked}
                            control={<Radio color="primary" />}
                            label="Easy"
                            labelPlacement="top"
                            />
                        <FormControlLabel
                            // disabled={(props.action!="create")?true:false}
                            // defaultValue={props.data.isOutdoor}
                            value="1"
                            name="difficulty"
                            onChange={handleChecked}
                            control={<Radio color="primary" />}
                            label="Normal"
                            labelPlacement="top"
                            />
                        <FormControlLabel
                            // disabled={(props.action!="create")?true:false}
                            // defaultValue={props.data.isOutdoor}
                            value="2"
                            name="difficulty"
                            onChange={handleChecked}
                            control={<Radio color="primary" />}
                            label="Hard"
                            labelPlacement="top"
                            />
                    </RadioGroup>
                    </FormControl>  
                </Grid>

        </Grid>

        <br></br><br></br>

        <Grid container spacing={2} alignItems="center">
            
            <Grid item>
                <FormLabel><b>Enable</b></FormLabel>
            </Grid>

            <Grid item>
                    <FormControl>
                    <RadioGroup row aria-label="enable" name="enable" defaultValue="top">
                        <FormControlLabel
                            // disabled={(props.action!="create")?true:false}
                            defaultValue={0}
                            value={1}
                            name="game1"
                            onChange={handleChecked}
                            control={<Checkbox color="primary" />}
                            label="Game1"
                            labelPlacement="top"
                            />
                        <FormControlLabel
                            // disabled={(props.action!="create")?true:false}
                            defaultValue={0}
                            value={1}
                            name="game2"
                            onChange={handleChecked}
                            control={<Checkbox color="primary" />}
                            label="Game2"
                            labelPlacement="top"
                            />
                        <FormControlLabel
                            // disabled={(props.action!="create")?true:false}
                            defaultValue={0}
                            value={1}
                            name="game3"
                            onChange={handleChecked}
                            control={<Checkbox color="primary" />}
                            label="Game3"
                            labelPlacement="top"
                            />
                        <FormControlLabel
                            // disabled={(props.action!="create")?true:false}
                            defaultValue={0}
                            value={1}
                            name="game4"
                            onChange={handleChecked}
                            control={<Checkbox color="primary" />}
                            label="Game4"
                            labelPlacement="top"
                            />
                        <FormControlLabel
                            // disabled={(props.action!="create")?true:false}
                            defaultValue={0}
                            value={1}
                            name="game5"
                            onChange={handleChecked}
                            control={<Checkbox color="primary" />}
                            label="Game5"
                            labelPlacement="top"
                            />
                        <FormControlLabel
                            // disabled={(props.action!="create")?true:false}
                            defaultValue={0}
                            value={1}
                            name="game6"
                            onChange={handleChecked}
                            control={<Checkbox color="primary" />}
                            label="Game6"
                            labelPlacement="top"
                            />        
                        <FormControlLabel
                            // disabled={(props.action!="create")?true:false}
                            defaultValue={0}
                            value={1}
                            name="game7"
                            onChange={handleChecked}
                            control={<Checkbox color="primary" />}
                            label="Game7"
                            labelPlacement="top"
                            />      
                    </RadioGroup>
                    </FormControl>  
                </Grid>

        </Grid>

        


        {/* <Grid container spacing={2} backgroudcolor="Red" >
                

               
                <Grid item xs={12} sm={4}>
                    
                    <FormControl style={{width:'100%'}} disabled={(props.data.LocationID)?true:false}>                        
                        <InputLabel >Location</InputLabel>
                        <Select name="LocationID" required value={(props.action!="create")?props.data.LocationID:gatewayConfig.LocationID}  onChange={(e)=>{handleSelectChanged(e);}}>
                            {info.map((d, i)=>{console.log(d); return <MenuItem key={i} value={d.LocationId}>{d.LocationName}</MenuItem>})}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    id="AreaName"
                    name="AreaName"
                    label="Area Name"
                    required
                    fullWidth
                    defaultValue={props.data.AreaName}
                    onChange={(e)=>handleChanged(e)}
                    />
                </Grid>
            </Grid>
          
            <Grid container spacing={3}>
                <Grid item>
                    <FormLabel><b>Gateway Config</b></FormLabel>
                </Grid>
            </Grid> */}
            

            {/* <Grid container spacing={2}>

                <Grid item xs={12} sm={4}>
                    <TextField
                    id="GSMID"
                    name="GSMID"
                    label="GSMID"
                    fullWidth
                    required
                    defaultValue={props.data.GSMID}
                    onChange={(e)=>handleChanged(e)}
                    />
                </Grid>
               
            </Grid> 

            {addThreshold(props.action)} */}
        </form>

            
    )
}

export default GatewayConfigForm