import React from 'react'
import User from '../../components/User/User'
import LoginVerifiy from '../../components/Login/LoginVerifiy'

const UserPage =()=>{
    LoginVerifiy()

    return <User />

}   
export default UserPage