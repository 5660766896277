import React, {useState, useEffect, useRef} from 'react';
import { useHistory, Redirect } from 'react-router-dom'
import axios from 'axios';
//import reduxStore from '../Reducers/store';
import {useSelector, useDispatch} from 'react-redux'
import {Loggingin, addToken, getToken, testing, addProfile} from '../Reducers/Actions'
import Profile from './Profile';
//import loginFormElements from './LoginFormElements.json'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles'   ;
import Container from '@material-ui/core/Container';

import InnoSpaceIcon from '../Icon/Inno Space logo.png'
import InnoSpaceIcon2 from '../Icon/login.png'
import axiosConfig from '../Utililty/AxiosConfig';


import { InputAdornment } from "@material-ui/core";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';








const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    icon: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },

  }));



const CopyRight=()=>{
    return(
        <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
        Sengital
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
    </Typography>
    )
}



const Login = (props)=>{
    const classes = useStyles()
    const dispatch = useDispatch();
    const history = useHistory()
    const errRef = useRef();
    
    const key = useSelector(state=>state.token)
    const isLogged = useSelector(state=>state.isLogged)
    const [errCount, setErrCount] = useState(0)
    const [loginData, setLoginData] = useState({});
    const [errMessage, setErrMessage] = useState("");
    //const {store, persistor} = reduxStore()
    //console.log(tokenSelector)

    //console.log(store)
    const handleSubmit = async (e)=>{
            e.preventDefault();
            const url = "https://api.techunt.hkrnd.com/api/v1/users/admin/login"

            axios.post(url, loginData)
            .then(res=>{
                
                if(res.data.status == "ok"){
                   dispatch(Loggingin({username:loginData.username}))
                   dispatch(addToken(res.data.data))
                   //isLogged(true)
                   const instance = axios.create({
                    
                  });
                  instance.defaults.headers.common['Authorization'] = `Bearer ${res.data.data.token}`;
                  instance.get("https://api.techunt.hkrnd.com/api/v1/users/profile").then((response)=>{dispatch(addProfile(response.data.data))})
                history.push('/Redeem')
                }}).catch(err=>{ 
                    //console.log(err.response)
                    if(err)
                    setErrMessage(err.response.data.error);
                    
                })
            //console.log(e.target.password.value)
      
    }

    const handleChanged =(e)=>{
        const newLoginData = {...loginData}
        newLoginData[e.target.id] = e.target.value;
        setLoginData(newLoginData)
        //console.log(newLoginData)
    }

    const handleErrMessage =()=>{
        if(errMessage!=""){
            return "*"+errMessage;
        }else{
            return "";
        }
    }

    useEffect(()=>{
        setErrCount(errCount+1);
        console.log(errMessage)
    }, [errMessage])
    /**
    useEffect(()=>{
        if(isLogged){
            loginData = [null]
            console.log(loginData);
        }
    },[isLogged])
    **/ 


    return (
        <Container component="main" maxWidth="md">

            <div className={classes.icon}>
            <Grid container spacing={5} alignItems={'center'} alignContent={'center'}>
                <Grid item xs>
                <img src={InnoSpaceIcon2} alt="Icon" width={"100%"} height={'100%'} />
                </Grid>
                <Grid item xs>
                <img src={InnoSpaceIcon} alt="Icon" width={"90%"} height={'90%'} />
                </Grid>
            </Grid>
            </div>
        
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            
            <div className={classes.paper}>

                <form className={classes.form}  onSubmit={e=>handleSubmit(e)} id="login">
                <TextField onChange={e=>handleChanged(e)}
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        helperText={errMessage == "USER_NOT_FOUND" ? errMessage : "" }
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleIcon  />
                              </InputAdornment>
                            )
                          }}
                       
                    />
                    
                    
                    <TextField onChange={e=>handleChanged(e)}
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        helperText={errMessage == "PASSWORD_NOT_MATCH" ? errMessage : "" }
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon  />
                              </InputAdornment>
                            )
                          }}
                    />
                    
                </form>
                
                <Box pt={3}>

                </Box>
               
                {/* <Grid container>
                    <Grid item xs>
                    <Link href="#" variant="body2">
                        Forgot password?
                    </Link>
                    </Grid>
                </Grid> */}
               
                <Button
                        form="login"
                        type="submit"
                        fullWidth
                        variant="outlined"
                        color="primary"
                        className={classes.submit}
                    >
                        <b>Sign In</b>
                    </Button>
            </div>
        <Box  mt={5}>
            <CopyRight />
        </Box>   
        </Container>
        </Container>
        
    )
}

export default Login

