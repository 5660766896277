import React from 'react'
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import AlertResult from '../Search/AlertResult';



  
  
// data is the props
const AlertMessageTable =(props)=>{

    
    /*
    const columns = [
        { field: 'id', headerName: 'Alert ID', hide:true, width:100 },
        { field: 'Date', headerName: 'Date',width:100 },
        { field: 'Time', headerName: 'Time',width:100 },
        { field: 'Location', headerName: 'Location',hide:true, width:180},
        { field: 'Area', headerName: 'Area', width:180},
        { field: 'Message', headerName:'Message', width:180}
        ]
    */
    return <AlertResult data={props.data} handleSelected={props.handleSelected}/>



}

export default AlertMessageTable;