import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';

export const SidebarItems = [
    {
        title: 'Dashboard',
        icon: <DashboardOutlinedIcon />,
        link:'/Redeem',

        nasted: [ 
        // {
        //     title: 'Latest',
        //     link: '/Dashboard/Latest'
        // },
        // {
        //     title: 'Configuration',
        //     link: '/Gateway'
        //     // link: '/Dashboard/Monlthy'
        // },
        // {
        //     title: 'Redeem',
        //     link: '/Redeem'
        // },
        ]
    },
    {
        title: 'Configuration',
        icon: <BuildOutlinedIcon />,
        link:'/Global',
        nasted: [
            // {
            //     title: 'Configuration',
            //     link: '/Gateway'
            //     // link: '/Dashboard/Monlthy'
            // },
            // {
            //     title: 'Location',
            //     link: '/Location'
            // },
            // {
            //     title: 'Area',
            //     link: '/Gateway'
            // },
            // {
            //     title: 'Sensor',
            //     link: '/Sensor'
            // },
            
            // {
            //     title: 'Control',
            //     link: '/Control'
            // },
        ]
    },
    // {
    //     title: 'Alert',
    //     icon: <NotificationsActiveOutlinedIcon />,
    //     link: '/Alert',
    //     nasted: []
    // },
    {
        title: 'Report',
        icon: <AssessmentOutlinedIcon />,
        link: '/Report',
        nasted: [
            // {
            //     title: 'Information',
            //     link: ''
            // },
            // {
            //     title: 'Certificate',
            //     link: '/Certificate'
            // },
        ]
    },
    {
        title: 'Settings',
        icon: <SettingsOutlinedIcon />,
        link:'#',
        nasted: [
            {
                title: 'Change Username/Password',
                link: '/User'
            },
            // {
            //     title: 'General',
            //     link: '/General'
            // },
        ]
    },
]