import React,{useState, useEffect} from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Navbar/Navbar';
import styled from 'styled-components';
import { makeStyles, useTheme } from '@material-ui/core';
import { composeClasses } from '@material-ui/data-grid';
import { CssBaseline } from '@material-ui/core';
import useStyles from '../Css/useStyle';

/*
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'fixed',
      maxWidth:false
      
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: {
        height: '70px',
        width: '100%',
        display: 'flex',

        top: '0',
        alignItems: 'center',
        color: 'white',
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#00506C',
  
    },
    content: {
      flexGrow: 1,
      paddingTop: '1vh',
      paddingLeft: '1vw',
      paddingRight: '1vw',
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      }
    },
  }));
*/
const navbar = {
    width: '100vw',
    height:'100vh'
}
const mainComponent = {
    paddingTop: '1vh',
    paddingLeft: '1vw',
    paddingRight: '1vw'
}

const Layout = ({ children }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const classes = useStyles()

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
      };

    useEffect(()=>{
        console.log("Now is :" +mobileOpen)
    },[mobileOpen])


    return (
            <div style={{flexGrow:'1'}}>
            <CssBaseline />

                <Navbar open={handleDrawerToggle}/>
                <Sidebar status={mobileOpen} open={handleDrawerToggle} />
                <main className={classes.content}>
                    
                    <div className={classes.toolbar} />
                   

                    {children}
                </main>
            </div>

    )
}
export default Layout;