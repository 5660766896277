import { FormLabel, Grid, TextField, Button, Container } from '@material-ui/core'
import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axiosConfig from '../Utililty/AxiosConfig'
import { useHistory } from 'react-router'
import axios from 'axios'
import {logout, addProfile,addToken } from '../Reducers/Actions'



const UpdateInfoForm =(props)=>{
    const [updateData, setUpdateData] = useState({username:""})
    const history = useHistory()
    const key = useSelector(state=>state.token)

    
    const dispatch = useDispatch()

    const handleSubmit = async (e)=>{
        dispatch(logout())
        history.push('/')
        const submitUrl = "/users/admin/update"
        await axiosConfig(key).post(submitUrl, updateData)

        
        
    }

    const handleChanged = async(e)=>{
        const newUpdateData = {...updateData}
        newUpdateData[e.target.id] = e.target.value
        setUpdateData(newUpdateData)
    }
    
    useEffect(()=>{
        setUpdateData(props.data)
    },[props.data])

    useEffect(()=>{
        console.log(updateData)
    },[updateData])
    

    return (
        //settings 
        <form noValidate onSubmit={e=>handleSubmit(e)} id="userInfo">
        <TextField onChange={e=>handleChanged(e)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                defaultValue={props.data.username}
                value={updateData.username}
                id="username"
                label="Username"
                name="username"
                autoFocus
            />
            <TextField onChange={e=>handleChanged(e)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="password"
                id="password"
                type='password'
            />

        </form>


                
                

    )

}

export default UpdateInfoForm

