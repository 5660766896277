import React, {useState, useEffect} from 'react'
import axiosConfig from '../Utililty/AxiosConfig'
import axios from 'axios'
import AlertResult from './AlertResult'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'

const Alert = () =>{
    
    const [alertInfo, setAlertInfo] = useState([])
    const [counter, setCounter] = useState(0);
    const key = useSelector(state=>state.token)

    const getAlertInfo = async()=>{
        const url = "users/game/listall" // users/game/listall
        const res = await axiosConfig(key).get(url)
        setAlertInfo(res.data.data)
    }

    useEffect(()=>{
        setCounter(counter+1)
    },[])

    useEffect(()=>{
        getAlertInfo()
    },[counter])

    useEffect(()=>{
        console.log(alertInfo)
        
    },[alertInfo])

    return (
        
      <>
        <Grid container xs={12} spacing={3} style={{margin:'0px', height:'80vh', width:'100%'}}>
          <Grid item xs={12}>
            <AlertResult data={alertInfo} handleSelected={""}/>
          </Grid>
        </Grid>
      </>
   
    )

}
export default Alert;