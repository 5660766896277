const initalState = ""
const userProfile = (state = ""  , action)=>{
    switch(action.type){
        case "UPDATE_PROFILE":
            const profile = action.profile
            return {...state, profile}
        case "SIGN_OUT":
            state = initalState;
            return {...state}
        default :
            return state;
        
    }

}

export default userProfile