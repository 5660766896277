import React from 'react'
import axios from 'axios'
import UpdatePasswordForm from './UpdatePasswordForm'
import UserInfo from './UserInfo'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import Profile from '../Login/Profile'
import LoginVerifiy from '../../components/Login/LoginVerifiy'

const User = () =>{
    
    return (
            
                <UserInfo  />


    )
   
       
}
export default User