import React,{useEffect, useState} from 'react'
import { DataGrid,GridToolbarContainer,
    GridToolbarExport } from '@material-ui/data-grid';
import {Grid} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import axiosConfig from '../Utililty/AxiosConfig';
import axios from 'axios'


/*
const rows =
[
{'id':1, 'Location':'TSW Area 1',"RH":"5%","Humidity":'5%','Rainging':'25%','Temperature':'25',"PM2.5":"50",'Date':"12/07","Time":"Day"},
{'id':2, 'Location':"TSW Area 1","RH":"5%","Humidity":'5%','Rainging':'25%','Temperature':'25',"PM2.5":"50",'Date':"12/07","Time":"Night"},
{'id':3,'Location':"TSW Area 1","RH":"5%","Humidity":'5%','Rainging':'25%','Temperature':'25',"PM2.5":"50",'Date':"13/07","Time":"Day"},
{'id':4,'Location':"TSW Area 1","RH":"5%","Humidity":'5%','Rainging':'25%','Temperature':'25',"PM2.5":"50",'Date':"13/07","Time":"Night"},
{'id':5,'Location':"TSW Area 1","RH":"5%","Humidity":'5%','Rainging':'25%','Temperature':'25',"PM2.5":"50",'Date':"14/07","Time":"Day"},
{'id':6,'Location':"TSW Area 1","RH":"5%","Humidity":'5%','Rainging':'25%','Temperature':'25',"PM2.5":"50",'Date':"14/07","Time":"Night"},
{'id':7,'Location':"TSW Area 1","RH":"5%","Humidity":'5%','Rainging':'25%','Temperature':'25',"PM2.5":"50",'Date':"15/07","Time":"Day"},
{'id':8,'Location':"TSW Area 1","RH":"5%","Humidity":'5%','Rainging':'25%','Temperature':'25',"PM2.5":"50",'Date':"15/07","Time":"Night"},
{'id':9,'Location':"TSW Area 1","RH":"5%","Humidity":'5%','Rainging':'25%','Temperature':'25',"PM2.5":"50",'Date':'16/07',"Time":"Day"},
{'id':10,'Location':"TSW Area 1","RH":"5%","Humidity":'5%','Rainging':'25%','Temperature':'25',"PM2.5":"50",'Date':'16/07',"Time":"Night"}
]*/



const CustomToolbar=()=> {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

const ExcelPreview=(props)=>{
    const key = useSelector(state=>state.token)
    const [data,setData] = useState([])
    const [cols, setCols] = useState([])
    //const [options, setOptions] = useState({})

    const getReportData = async()=>{/*
        console.log(props.options)
        const URL ='/reports/irrgation'
        //const res = await axiosConfig(key).get(URL,props.options)

        const res = await axiosConfig(key).get(URL,{testing:1})
        console.log(res)
        if(res.data)
        
            setData(res.data.data)
            setCols(res.data.columns)*/

        const res = await axiosConfig(key).get('/users/game/listall') 
        if(res.data){
            setData(res.data.data)
            const columns = [
                //{ field: 'id', headerName: 'ID', width: 90 },
                {field:"user_id", headerName:"ID", type: 'number', width:100}, //72
                {field:"username", headerName:"Username", headerAlign: 'center', align: 'center', width:200}, //200
                {field:"institution", headerName:"Institution", headerAlign: 'center', align: 'center', width:180}, //180
                {field:"number_of_participants", headerName:"N.O.P.", headerAlign: 'center', align: 'center', width:150}, //100
                {field:"difficultyTag", headerName:"Difficulty", headerAlign: 'center', align: 'center',  width:140},
                {field:"isClear", headerName:"isClear", headerAlign: 'center', type: 'boolean', width:160},
                {field:"isRedeemed", headerName:"isRedeemed", headerAlign: 'center', type: 'boolean',  width:160}, //140
                {field:"redeemCode", headerName:"Redeem Code", headerAlign: 'center', align: 'center',  width:175}, //175
                {field:"created_at", headerName:"Date", headerAlign: 'center', align: 'center',  width:140},
                {field:"game1", headerName:"Game1", headerAlign: 'center', align: 'center',  width:130},
                {field:"game2", headerName:"Game2", headerAlign: 'center', align: 'center',  width:130},
                {field:"game3", headerName:"Game3", headerAlign: 'center', align: 'center',  width:130},
                {field:"game4", headerName:"Game4", headerAlign: 'center', align: 'center',  width:130},
                {field:"game5", headerName:"Game5", headerAlign: 'center', align: 'center',  width:130},
                {field:"game6", headerName:"Game6", headerAlign: 'center', align: 'center',  width:130},
                {field:"game7", headerName:"Game7", headerAlign: 'center', align: 'center',  width:130}
                //145
                
                //align: 'center'
            ]
            setCols(columns)
        }

    }

    

    useEffect(()=>{
        if(true){
            getReportData()
            console.log("Getting")
        }
        
    },[props])


    return (
        //report html
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <div style={{ height: '50vh', width: '100%' }}>
                    <div style={{ display: 'flex', height: '140%' }}>
                        <div style={{ flexGrow: 1 }}>
                            
                            <DataGrid height={'100%'} rows={data} columns={cols} components={{
                                Toolbar: CustomToolbar,
                            }} />
                            
                            
                        </div>
                    </div>
                </div>

            </Grid>
        </Grid>
    );
}

export default ExcelPreview