import React from 'react';
import {Container, Button} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles'   ;
import GatewayConfigForm from './GatewayConfigForm';
import SensorConfigForm from './SensorConfigForm'
import LocationConfigForm from './LocationConfigForm'
import ControlConfigForm from '../Control/ControlConfigForm'
import CertConfigForm from '../Report/CertConfigForm'
import axios from 'axios';


const ConfigurationForm =(props)=> {

    //console.log(props)
    const SelectConfigForm =(type)=>{
        switch(type.toString().toLowerCase()){
            case "gateways":
                return <GatewayConfigForm data={props.data} action={props.action}/>
            // case "sensors":
            //     return <SensorConfigForm data={props.data} action={props.action}/>
            case "certificate":
                return <CertConfigForm data={props.data} action={props.action}/>
            case "control":
                return <ControlConfigForm data={props.data} action={props.action} /> 
            default:
                return <div></div>            

        }
    }

    return (
        <Container maxWidth="lg" >
                {SelectConfigForm(props.type)}
        </Container>
    );
  }

export default ConfigurationForm