import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const SidebarContainer = styled.nav`
    min-width:250px;

    height: 100vh;
    width: 20vw;
    display: flex;
    justify-content: center;
    background-color: #00506C;
    transition: 850ms;
    z-index: 1100;

    @media screen and (max-width: 768px) {
        width: 20vw;
        position: fixed;
    }
`;

export const SidebarMenu = styled.ul`
    height: auto;
    width: 100%;
    padding: 0;
`;

// temp
export const SidebarLogo = styled.div`
    height: 300px
    width: 100%;
    display: flex;
    align-items: bottome;
    justify-content: center;
    font-weight: bold;
`;
//

export const SidebarMenuItem = styled.li`
    display: block;
    list-style: none;
    align-items: center;
    justify-content: flex-start;
    background-color: ${({ subitem }) => subitem ? '#003194' : 'transparent' };
    transition: 850ms;
`;

export const ItemLink = styled(NavLink)`
    height: ${({ subitem }) => subitem ? '45px' : '60px' };
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    color: aliceblue;
    transition: all .3s ease-out;

    &:hover {
        background-color: #00aade;
        transition: all .3s ease-out;
    }
`;

export const ItemIcon = styled.div`
    flex: 30%;
    display: grid;
    place-items: center;
`;

export const ItemTitle = styled.div`
    flex: 40%;
`;