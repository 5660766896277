import React, {useState, useEffect} from 'react'
import { useHistory, Redirect } from 'react-router-dom'

import { Avatar,Button,CssBaseline,TextField,FormControlLabel,Checkbox,Link,Grid,Box } from '@material-ui/core';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles'   ;
import Container from '@material-ui/core/Container';

import axios from 'axios'

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

const CopyRight=()=>{
    return(
        <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
        Sengital
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
    </Typography>
    )
}

const ForgetPassword = (props)=>{
    const [email, setEmail] = useState([])
    const [description, setDescription] = useState([])
    const classes = useStyles()

    const handleChanged =(e)=>{
        const newEmail = {...email}
        newEmail[e.target.id] = e.target.value;
        setEmail(newEmail)
        //console.log(newLoginData)
    }

    const handleSubmit =(e)=>{

        const submitUrl = ""

    }

    const descriptionText = "TTTTTTTTTTTTTTTTTTT"

    useEffect(()=>{
        if(descriptionText==""){
            setDescription(descriptionText)
        }

    },[])

    return (
    
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                     Forget Password
                </Typography>
                <form className={classes.form} noValidate onSubmit={e=>handleSubmit(e)} id="login">
                <TextField onChange={e=>handleChanged(e)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField onChange={e=>handleChanged(e)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                   
                <Grid container>
                    <Grid item xs>
                    <Link href="#" variant="body2">
                        Forgot password?
                    </Link>
                    </Grid>
                </Grid>
                </form>
                <Button
                        form="login"
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
            </div>
            <Box  mt={8}>
                <CopyRight />
            </Box>   
        </Container>)

    





}

export default ForgetPassword