import React from 'react';

import SensorResult from '../../components/Search/SenorResult'
import LoginVerifiy from '../../components/Login/LoginVerifiy'

const SenorPage = () =>{

    LoginVerifiy();
    return (
        <SensorResult />

   )
}

export default SenorPage;