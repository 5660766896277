import {createStore} from 'redux'
import allReducer from '../Reducers'
import storage from 'redux-persist/lib/storage'
import { persistReducer,persistStore } from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { generateKey } from 'fast-key-generator';

const key = generateKey({
  size: 10,
  prefix: 'key_',
  chartype: 'alpha',
  
});

//const final = key
const persistConfig = {
    key: 'root',
    storage,
    
    // transforms:[encryptTransform({
    //     secretKey: 'sengital',
    //     onError: function (error) {
    //       // Handle the error.
    //     },
    //   }),]
      
      
      
  }
   
const persistedReducer = persistReducer(persistConfig, allReducer)

export default (()=>{
    //console.log(key)
    let store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
    let persistor = persistStore(store)
    return {store, persistor}
})
  