import React,{useEffect, useState} from 'react'
import Login from '../../components/Login/Login'
import ForgetPassword from '../../components/Login/Forgetpassword'

const LoginPage =()=>{

    const [state, setState] = useState("Login")

    const updateCurrentPage = (state) =>{
        switch(state.toUpperCase()){
            case "LOGIN":
                return <Login updateState={updateState}/>
            case "FORGET":
                return <ForgetPassword updateState={updateState} />
        }
    }

    const updateState =(e)=>{
        if(e){
            setState(e)
        }
    }

    
    return (
        updateCurrentPage(state)
    )

}

export default LoginPage