import React, {useState, useEffect} from 'react'
import {InputLabel,FormLabel, Grid, MenuItem, TextField, Select, Container, FormControl, Button } from '@material-ui/core';
import axios from 'axios';
import { useSelector } from 'react-redux';
import axiosConfig from '../Utililty/AxiosConfig'


const ControlForm =(props)=>{

    const [gatewayConfig, setGatewayConfig] = useState()
    const key = useSelector(state=>state.token)


    const handleChanged =(e)=>{
        const newGatewayConfig = {...gatewayConfig}
        newGatewayConfig[e.target.id] = e.target.value;
        setGatewayConfig(newGatewayConfig)
        //console.log(newGatewayConfig)
    }

    const handleSelectChanged = (e) =>{
        const newSelectConfig = {...gatewayConfig}
        newSelectConfig[e.target.name] = e.target.value
        setGatewayConfig(newSelectConfig)
        //setSelectedLocation(e.target.value)
        // console.log({...gatewayConfig})
    }
    
    const handleSubmit = async (e)=>{
        e.preventDefault()
        /*
        const submitUrl = "/api/v1/gateways/"+props.action
        */
        gatewayConfig.user_id = props.data.user_id;
        console.log(gatewayConfig.user_id)
        const submitUrl = "/users/game/redeem"
        await axiosConfig(key).post(submitUrl, gatewayConfig)
        window.location.reload();
        
        //await axiosConfig(key).post("/gateways/update/ip", gatewayConfig)
    }
    
    useEffect(() => {
        // console.log(gatewayConfig)
       //console.log(key)
    }, [gatewayConfig])

    return (


        <form onSubmit={(e)=>{handleSubmit(e);}} id="controlConfig">
            <Grid container direction="row">
                <FormLabel><b>Control Config</b></FormLabel>
                
            </Grid>
            <Grid container> <br /></Grid>

            <Grid container spacing={2} backgroudcolor="Red">
                    
                    <Grid item xs={12}>
                        <InputLabel id="isRedeemed">Redeem</InputLabel>

                        <Select 
                            label="Redeem"
                            name={"isRedeemed"} 
                            value={gatewayConfig} 
                            onChange={handleSelectChanged}
                            fullWidth
                        >
                            {/* <MenuItem value={666}>Yes/No</MenuItem> */}
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>

                        </Select>
                    </Grid>
                    {/* <Grid item>
                        <div>&nbsp;</div>
                    </Grid>
                    

                    <Grid item xs={12} sm={5}>
                        <TextField
                        required
                        id="user_id"
                        name="user_id"
                        label="ID"
                        fullWidth
                        defaultValue={props.ip}
                        onChange={(e)=>{handleChanged(e)}}
                        />
                    </Grid>
                    </Grid> */}
          
                </Grid>
            </form>
                

                
        
    )

    
}
export default ControlForm
