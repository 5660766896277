import React, {useEffect, useState} from 'react'
import axios from 'axios'
import AlertDataTable from './AlertMessageTable'
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography
  } from '@material-ui/core';

import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';


/*
const cols =[    
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'date', headerName: 'Date', width: 110 },
    { field: 'location', headerName: 'Location', width: 110 },
    { field: 'alertMessage', headerName: 'Alert Message', width: 110 }

]*/


const useStyles = makeStyles({
    root: {
      maxWidth: 'md',
      height:'75vh',

    },
    CardContent:{
        flex: '1 0 auto',
        height:'100%'
    }    
})

const AlertCard = (props)=>{
    const classes = useStyles()
    const [alert, setAlert]= useState([])


    /*
    const GetAlert = async (location, area)=>{
        const url = "" // alert api
        const result = await axios.get(url,{location:location, area:area})
        setAlert(result)
    }*/

    /*
    useEffect(()=>{

       //GetAlert(location, area)
        

    },[props.location, props.area])*/


    return (
        <Card className={classes.root} >
            <CardContent className={classes.CardContent}>
                <AlertDataTable data={props.data} handleSelected={props.handleSelected}/>
            </CardContent>

           
        </Card>


    )

}

export default AlertCard