import React, {useState, useEffect} from 'react'
import { Grid } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import DisplayTable from './DisplayTable'

const AlertResult =(props)=>{

    console.log(props)

    
    const columns = [
        //{ field: 'id', headerName: 'ID', width: 90 },
        {field:"user_id", headerName:"ID", type: 'number', width:72}, //72
        {field:"username", headerName:"Username", headerAlign: 'center', align: 'center', width:200}, //200
        {field:"institution", headerName:"Institution", headerAlign: 'center', align: 'center', width:180}, //180
        {field:"number_of_participants", headerName:"N.O.P.", headerAlign: 'center', align: 'center', width:100}, //100
        {field:"isClear", headerName:"Clear", headerAlign: 'center', type: 'boolean', width:160},
        {field:"isRedeemed", headerName:"Redeemed", headerAlign: 'center', type: 'boolean',  width:140}, //140
        {field:"redeemCode", headerName:"Redeem Code", headerAlign: 'center', align: 'center',  width:175}, //175
        {field:"created_at", headerName:"Date", headerAlign: 'center', align: 'center',  width:136}, //145
        {field:"difficultyTag", headerName:"Difficulty", headerAlign: 'center', align: 'center',  width:140},
        {field:"game1", headerName:"Game1", headerAlign: 'center', align: 'center',  width:130},
        {field:"game2", headerName:"Game2", headerAlign: 'center', align: 'center',  width:130},
        {field:"game3", headerName:"Game3", headerAlign: 'center', align: 'center',  width:130},
        {field:"game4", headerName:"Game4", headerAlign: 'center', align: 'center',  width:130},
        {field:"game5", headerName:"Game5", headerAlign: 'center', align: 'center',  width:130},
        {field:"game6", headerName:"Game6", headerAlign: 'center', align: 'center',  width:130},
        {field:"game7", headerName:"Game7", headerAlign: 'center', align: 'center',  width:130}

        
        
        //align: 'center'
    ]

    return (

            <DisplayTable rows={props.data} cols={columns} handleSelected={props.handleSelected} />
        
    )

}

export default AlertResult