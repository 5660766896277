import React from 'react';
import Location from '../../components/Search/Location'
//import GatewayResult from '../../components/Search/GatewayResult'
import LoginVerifiy from '../../components/Login/LoginVerifiy'

const LocationPage = () =>{

    LoginVerifiy()
    
    return (
        
        <Location />
    )
}

export default LocationPage;