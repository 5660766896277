import React, { useState } from 'react'

import './Chart.css'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Bar from './BarChart'
import Pie from './PieChart'
import Line from './LineChart'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import { Switch } from 'react-router-dom';
import { CardTitle } from 'reactstrap';

const useStyles = makeStyles({
    root: {
        display: 'block',
        transitionDuration: '0.3s',
        width:'100%'
    },
    title:{
        margin:'5px'
    }

})

const ChartWithCard =(props)=>{
        const classes = useStyles();
        //const [data, setData] = useState([]);
        //const chartData = [];

        const SelectChartType =(type)=>{
            switch(type){
                case "Bar":
                    return <Bar data={props.data}/>
                case "Line":
                    return <Line data={props.data}/>
                case "Pie":
                    return <Pie data={props.data}/>
            }
        }
        

        return(
                <Card className={classes.root} >
                    <CardTitle className={classes.root}>
                        <Typography variant="h6"><b>Placeholder</b></Typography>
                    </CardTitle>
                    <CardContent>
                        {SelectChartType(props.type)}
                    </CardContent>
                </Card> 
               
           
        )
    


    
}

export default ChartWithCard;

