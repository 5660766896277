import React from 'react';
import GatewayResult from '../../components/Search/GatewayResult'
import LoginVerifiy from '../../components/Login/LoginVerifiy'


const GatewayPage = () =>{

    LoginVerifiy()
    
    return (
        <GatewayResult />

   )
}

export default GatewayPage;