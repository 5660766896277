import React, {useState, useEffect} from 'react'
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography
  } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles({

})


const InformationCard = (props)=>{
  
    const classes = useStyle()

    const checkDecimal = (data) =>{
        if(data%1!=0){
          return data.toFixed(2)
        }else{
          return data
        }

    }

   //console.log(props.data.Hx.toFixed(2))

    return (
        <Card sx={{ height: '100%' }}>
          <CardContent>
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                >
                  {(props.option!="Hx")?props.data.TxItem:props.data.HxItem}
                </Typography>
                
              </Grid>
            </Grid>
            <Grid container>
                <Grid item>
                  <Typography>
                    {(props.data && props.option!="Hx")?checkDecimal(props.data.Tx)+""+props.data.TxUnit
                    :checkDecimal(props.data.Hx)+""+props.data.HxUnit}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item>
                    <Typography
                    color="textSecondary"
                    gutterBottom style={{fontSize:'16px'}}>
                        Last updated:
                        {<br />}
                        {props.data.ArrivalDate + " " + props.data.ArrivalTime}
                    </Typography>
                </Grid>
            </Grid>
          </CardContent>
      </Card>
    )
}

export default InformationCard