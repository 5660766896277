import { Divider } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

//import Card from '../../components/Chart/CardTesting/Card'
import { FormLabel ,FormHelperText,Grid, Container, Select, MenuItem, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import AlertCard from '../../components/Chart/AlertCard';
import Card from '../../components/Card/Card'
import Chart from '../../components/Chart/ChartWithCard'
import InfoCard from '../../components/Chart/InformationCard';
import { Typography } from '@material-ui/core';
import LoginVerify from '../../components/Login/LoginVerifiy'
import Latest from './LatestPage';
import Period from './PeriodReport'
import { useParams } from 'react-router-dom';





const Dashboard = () => {
    
    //const [dashboardType, setDashboardType] = useState("Monthly")
    const option = useParams()
    console.log(option.id)
    LoginVerify()

    const SelectDashboardDisplay = (options) =>{
        switch(options){
            case "Latest":
                return <Latest type={options} />
            // case "Monthly":
            // case "Yearly":
            //     console.log(options)
                return <Period type={options}/>
        }
    }


    
    return SelectDashboardDisplay(option.id)

}

export default Dashboard;
