import React, {useState,useEffect} from 'react'
import { DataGrid } from '@material-ui/data-grid';
import {Button, Container, Grid} from '@material-ui/core';
import ConfigurationDialog from './ConfigurationDialog';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RedeemIcon from '@material-ui/icons/Redeem';
import CertIcon from '@material-ui/icons/Note';
import { makeStyles } from '@material-ui/styles';

// data is the props
const SearchResultTable =(props)=>{
    const [selectedRow, setSelectedRow] = useState({});
    
    //console.log(props)
    //console.log(props.rows[0])
    //const actions = ["update","delete"]
    const createButton =()=>{
        return <ConfigurationDialog data={""} type={props.type} action={"create"} />
    }

    const handleCellClick = (params, event) =>{
      event.stopPropagation();
    }

    const handleRowClick = (params, event) =>{
      event.stopPropagation();
    }

    const buttons = [
      //   {
      //   field: 'Action',
      //   width: 80,
      //   sortable: false,
      //   disableClickEventBubbling: false,
      //   disableColumnMenu:true,
      //   resizable: false,
      //   headerAlign: 'center',

      //   renderHeader: () => (
             
      //           <EditIcon />

      //     ),
            
      //   renderCell:()=>{
      //       return <ConfigurationDialog data={selectedRow} type={props.type} action={'update'}/>
              
      //   }
        
                
      // },
      // {
      //   field: 'Delete',
      //   width: 80,
      //   sortable: false,
      //   resizable: false,
      //   disableClickEventBubbling: false,
      //   disableColumnMenu:true,
      //   headerAlign: 'center',

      //   renderHeader: () => (
             
      //       <DeleteIcon />

      // ),
        
            
      //   renderCell:()=>{
      //       return <ConfigurationDialog data={selectedRow} type={props.type} action={'delete'}/>
              
      //   }
        
                
      // },
      {
        field: 'Redeem',
        width: 180,
        sortable: false,
        resizable: false,
        disableClickEventBubbling: false,
        disableColumnMenu:true,
        headerAlign: 'center',

        renderHeader: () => (
             
            <RedeemIcon />

      ),
        
            
        renderCell:(cellValue)=>{
            return <ConfigurationDialog data={cellValue.row} type={props.type} action={'redeem'}/>
              
        }
        
                
      },
    
    
    ]

    const buttonss = [
      {
        field: 'Cert',
        width: 80,
        sortable: false,
        resizable: false,
        disableClickEventBubbling: false,
        disableColumnMenu:true,
        headerAlign: 'center',

        renderHeader: () => (
             
            <CertIcon />

      ),
        
            
        renderCell:(cellValue)=>{
            return <ConfigurationDialog data={cellValue.row} type={props.type} action={'cert'}/>
              
        }
        
                
      },
    
    
    ]


    const button = [
        {
        field: 'Action',
        width: 80,
        sortable: false,
        disableClickEventBubbling: false,
        disableColumnMenu:true,
        resizable: false,
        headerAlign: 'center',

        renderHeader: () => (
             
                <EditIcon />

          ),
            
        renderCell:()=>{
            return <ConfigurationDialog data={selectedRow} type={props.type} action={'update'}/>
              
        }
        
                
      },
    //   {
    //     field: 'Delete',
    //     width: 80,
    //     sortable: false,
    //     resizable: false,
    //     disableClickEventBubbling: false,
    //     disableColumnMenu:true,
    //     headerAlign: 'center',

    //     renderHeader: () => (
             
    //         <DeleteIcon />

    //   ),
        
            
    //     renderCell:()=>{
    //         return <ConfigurationDialog data={selectedRow} type={props.type} action={'delete'}/>
              
    //     }
        
                
    //   },
      
    
    
    ]
   
    //console.log(selectedRow)
    const finalCols = (props.table != "Game")?((props.table != "Cert")?buttons.concat(props.columns):props.columns.concat(buttonss)):props.columns.concat(button)

    useEffect(()=>{
        console.log(selectedRow)
    })
    


    //console.log(props.rows)
    //console.log(props.columns)
    /*
     return(
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid rows={props.data} columns={props.columns} pageSize={5} />
        </div>
    )
    */

    const useStyles = makeStyles({
      root: {
          backgroundColor: '#FFFFFF',
      },
    });

    const classes = useStyles()

    
    return(
        <>
            
                        <Grid item xs={12} style={{height:'100%'}} className={classes.root}>
                        {<DataGrid height='100%'  rows={props.rows} columns={finalCols} autoPageSize onRowClick={handleRowClick} onCellClick={handleCellClick}/>}
                        </Grid>
        </>
    )





}

export default SearchResultTable;