import './App.css';
import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {addToken, isLogged, logout} from './components/Reducers/Actions'
import Dashboard from './pages/Dashboard/Dashboard';
import DashboardLayout from "./components/Layout/DashboardLayout";
import GatewayPage from "./pages/Configuration/GatewayPage"
import SensorPage from "./pages/Configuration/SenorPage"
import ConfigPage from './pages/Configuration/ConfigPage'
import LoginPage from './pages/Login/LoginPage'
import LocationPage from './pages/Configuration/LocationPage';
import ReportPage from './pages/Report/ReportPage';
import CertificatePage from './pages/Report/Certificate';
import LoginVerify from './components/Login/LoginVerifiy'

import {
  BrowserRouter as Router,
  Redirect,
  Route as DefaultRoute,
  Switch
} from 'react-router-dom'
import AlertPage from './pages/Alert/AlertPage';
import ControlPage from './pages/Control/ControlPage';
import UserPage from './pages/User/UserPage';
import axiosConfig from './components/Utililty/AxiosConfig';


const DefaultLayout = ({ children }) => (
  <Fragment>
    {children}
  </Fragment>
)

const Route = ({
  component: Component,
  layout: Layout = DefaultLayout,
  ...rest
}) => {
  return (
    <DefaultRoute
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}


window.currentPage = "Dashboard"
function App() {

  const logging = useSelector(state => state.isLogged)
  const dispatch = useDispatch()
  

  /*
  useEffect(() => {    

    //console.log(info)
      //console.log("Token exists")
      const interval = setInterval(()=>{
        /*
        const refresh = async()=>{
          const refreshUrl = "http://18.167.84.159:5000/api/v1/users/refresh_token"
          const refreshToken = key.refreshToken
          console.log(refreshToken)
  
          
          
          const res = await axios.post(refreshUrl, {id:1,refreshToken:refreshToken})
          
          if(res.data){
              dispatch(addToken(res.data.data))
              
          }
      //}

      //refresh()
        
       
        //console.log(result)

        console.log(key.refreshToken)
      },50000);
      return () => clearInterval(interval)
   
  
    
  }, [])*/
  
  return (
    <div className='App' style={{width: '100%'}}>
      <Router>
        <Switch>
          <Route exact path="/Login" component={LoginPage} layout={DefaultLayout} />
          <Route exact path="/">
            {(logging.isLogged&& logging.isLogged!=false)?<Redirect to="/Redeem" />:<Redirect to="/Login">{/**dispatch(logout())**/}</Redirect>}
          </Route>
          <Route exact path="/Dashboard/:id" component={Dashboard} layout={DashboardLayout}/>
          <Route exact path="/Global" component={GatewayPage} layout={DashboardLayout} />
          <Route exact path="/Sensor" component={SensorPage} layout={DashboardLayout} />
          <Route exact path="/Location" component={LocationPage} layout={DashboardLayout} />
          <Route exact path="/Report" component={ReportPage} layout={DashboardLayout} />
          <Route exact path="/Certificate" component={CertificatePage} layout={DashboardLayout} />
          <Route exact path="/Alert" component={AlertPage} layout={DashboardLayout} />
          <Route exact path="/Redeem" component={ControlPage} layout={DashboardLayout} />
          <Route exact path="/User" component={UserPage} layout={DashboardLayout} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
